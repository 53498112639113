import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronRight, FileText, Linkedin, Twitter, BarChart } from 'lucide-react';
import { Link } from 'react-router-dom';

/**
 * Navigation item component for header links
 * Handles both internal and external links with proper attributes
 */
const NavItem = ({ item, scrolled }) => {
    const href = item === 'Research'
        ? 'https://medium.com/exa-group'
        : (item === 'Home'
            ? '/'
            : `/${encodeURIComponent(item.toLowerCase().replace(/\s+/g, '-'))}`);

    // For external links, use <a> with security attributes
    if (href.startsWith('http')) {
        return (
            <a
                href={href}
                rel="noopener noreferrer"
                target="_blank"
                className={`transition-all duration-300 font-medium ${scrolled ? 'text-white hover:text-gray-200' : 'text-white hover:text-gray-200'}`}
            >
                {item}
            </a>
        );
    }

    // For internal links, use React Router's Link
    return (
        <Link
            to={href}
            className={`transition-all duration-300 font-medium ${scrolled ? 'text-white hover:text-gray-200' : 'text-white hover:text-gray-200'}`}
            onClick={() => window.scrollTo(0, 0)}
        >
            {item}
        </Link>
    );
};

/**
 * About Us page component
 * Displays information about the team and company background
 */
const AboutUs = () => {
    // State for mobile menu visibility and page scroll position
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [landingPadding, setLandingPadding] = useState('pt-32 pb-24 md:pt-44 md:pb-32');
    const [activeSection, setActiveSection] = useState('about');
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [selectedContributor, setSelectedContributor] = useState(null);

    const navItems = ['Treasury', 'Research', 'About Exa'];

    // Handle scroll events to adjust header and landing section appearance
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 50);
            setShowScrollTop(scrollPosition > 600);

            if (scrollPosition > 500) {
                setLandingPadding('pt-20 pb-16 md:pt-28 md:pb-20');
            } else if (scrollPosition > 200) {
                setLandingPadding('pt-24 pb-20 md:pt-36 md:pb-24');
            } else {
                setLandingPadding('pt-32 pb-24 md:pt-44 md:pb-32');
            }

            // Determine active section for mobile navigation
            const sections = document.querySelectorAll('section[id]');
            sections.forEach(section => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                if (scrollPosition >= sectionTop - 200 && scrollPosition < sectionTop + sectionHeight - 200) {
                    setActiveSection(section.getAttribute('id'));
                }
            });
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Function to scroll to top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Core team member data
    const coreContributors = [
        {
            name: 'Gary',
            role: 'Tokenomics and GTM',
            description: 'Web3 Generalist and builder, passionate about open data, privacy, and decentralized technologies',
            image: '/Worked for Logos copy/Profile Pictures/gary.png',
            twitter: 'https://x.com/kuerax',
            tags: ['Tokenomics', 'Web3', 'Privacy']
        },
        {
            name: 'Andrea',
            role: 'Tokenomics and Treasury',
            description: 'Passionate about OSS, Decentralized AI and DeFi with strong competencies in Token Design, Financial Markets, Social Economics and Product Management',
            image: '/Worked for Logos copy/Profile Pictures/andrea.jpg',
            twitter: 'https://x.com/751_eth',
            tags: ['Tokenomics', 'DeFi', 'Finance']
        },
        {
            name: 'Noelle',
            role: 'Procurement and Advisory',
            description: "Well versed DLT advisor focused on onboarding companies, data scientists, tech hippies and builders onto the decentralized data economy and AI bandwagon",
            image: '/Worked for Logos copy/Profile Pictures/noelle.png',
            twitter: 'https://x.com/N_E_Siri',
            tags: ['Advisory', 'Blockchain', 'Web3']
        },
        {
            name: 'Marco',
            role: 'Ecosystem Growth and Decentralized Governance',
            description: 'Passionate about ecosystem growth, decentralized governance, and sustainable Web3 adoption. Experienced in building growth strategies, fostering developer engagement, and driving collaborative initiatives across blockchain ecosystems',
            image: '/Worked for Logos copy/Profile Pictures/marco.png',
            twitter: 'https://x.com/GrendelMarco',
            tags: ['Governance', 'Strategy', 'Ecosystem']
        },
        {
            name: 'Alberto',
            role: 'Legal',
            description: 'Specialized in DeFi and cross-border crypto regulations in Europe, Switzerland and the UAE. Passionate about compliance, risk management and serving as AML officer for blockchain companies',
            image: '/Worked for Logos copy/Profile Pictures/Alberto.jpg',
            linkedin: 'https://www.linkedin.com/in/alberto-borri-970501163',
            tags: ['Legal', 'Compliance', 'DeFi']
        },
        {
            name: 'ZegDatHetKan',
            role: 'Token Engineering',
            description: 'Researchers, consultant and developer in tokenenomics systems',
            image: '/Worked for Logos copy/Profile Pictures/ZegDatHetKan.png',
            twitter: 'https://x.com/ZegDatHetKan',
            tags: ['Tokenomics', 'Simulations', 'Risk Management']
        },
        {
            name: '0xCuter',
            role: 'LP Connoisseur',
            description: 'Seed investor at Dewhales Capital and PvP/E markets enjoyooor',
            image: '/Worked for Logos copy/Profile Pictures/0xcuter.jpeg',
            twitter: 'https://x.com/0xcuter',
            tags: ['Enjoyooor', 'Private LP', 'Seed Investor']
        }
    ];

    // Partner company logos with their respective websites
    const trustedBy = [
        {
            logo: '/Worked for Logos copy/Polygon.png',
            website: 'https://polygon.technology',
            name: 'Polygon'
        },
        {
            logo: '/Worked for Logos copy/enso.png',
            website: 'https://enso.build',
            name: 'Enso Finance'
        },
        {
            logo: '/Worked for Logos copy/zkSync.png',
            website: 'https://zksync.io',
            name: 'zkSync'
        },
        {
            logo: '/Worked for Logos copy/near.org.png',
            website: 'https://near.org',
            name: 'NEAR Protocol'
        },
        {
            logo: '/Worked for Logos copy/ocean.png',
            website: 'https://oceanprotocol.com',
            name: 'Ocean Protocol'
        },
        {
            logo: '/Worked for Logos copy/roko network.png',
            website: 'https://roko.network',
            name: 'Roko Network'
        },
        {
            logo: '/Worked for Logos copy/gyroscope.jpg',
            website: 'https://gyro.finance',
            name: 'Gyroscope'
        },
        {
            logo: '/Worked for Logos copy/forefront.png',
            website: 'https://forefront.market',
            name: 'Forefront'
        }
    ];

    return (
        <div className="font-sans bg-white text-black">
            {/* Fixed navigation header - consistent with Home component */}
            <header className={`fixed w-full top-0 z-50 transition-all duration-500 backdrop-blur-sm ${scrolled ? 'bg-black/90 shadow-lg py-2' : 'bg-transparent py-3'}`}>
                <div className="container mx-auto px-4">
                    <div className="flex justify-between items-center">
                        {/* Logo/Brand */}
                        <Link
                            to="/"
                            className={`font-heading text-xl md:text-2xl font-bold transition-all duration-300 ${scrolled ? 'text-white' : 'text-white'} hover:scale-105`}
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            ExaGroup
                        </Link>

                        {/* Desktop Navigation */}
                        <div className="flex items-center space-x-6">
                            <nav className="hidden md:block">
                                <ul className="flex space-x-6">
                                    {navItems.map((item) => (
                                        <li key={item}><NavItem item={item === 'Research' ? 'Research' : item} scrolled={scrolled} /></li>
                                    ))}
                                </ul>
                            </nav>

                            {/* Call to Action Button - Desktop */}
                            <a
                                href="https://calendly.com/exaresearch"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`hidden md:block px-6 py-2 rounded-full text-sm font-bold transition-all duration-300 transform hover:scale-105 ${scrolled ? 'bg-white text-black hover:bg-gray-200' : 'bg-white text-black hover:bg-gray-200'}`}
                            >
                                Book a Call
                            </a>

                            {/* Mobile Menu Toggle */}
                            <button
                                className="md:hidden transform hover:scale-105 transition-transform duration-300"
                                onClick={() => setMobileMenuOpen(true)}
                                aria-label="Open menu"
                            >
                                <Menu className="w-7 h-7 text-white" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {/* Mobile menu overlay - Improved with smoother animations */}
            <div
                className={`fixed inset-0 bg-black/90 z-50 backdrop-blur-sm transition-all duration-500 ${mobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                onClick={() => setMobileMenuOpen(false)}
            >
                <div
                    className={`fixed right-0 top-0 bottom-0 w-4/5 max-w-sm bg-black shadow-2xl transform transition-all duration-500 ease-in-out ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* Mobile menu content - Enhanced with better spacing and animations */}
                    <nav className="p-8 pt-16 h-full flex flex-col">
                        <div className="flex justify-end mb-10">
                            <button
                                onClick={() => setMobileMenuOpen(false)}
                                className="p-2 rounded-full hover:bg-gray-800 transition-colors duration-300"
                                aria-label="Close menu"
                            >
                                <X className="w-7 h-7 text-white" />
                            </button>
                        </div>
                        <ul className="space-y-6 flex-1">
                            <li>
                                <Link
                                    to="/"
                                    className="block text-xl font-heading font-bold text-white mb-2 transition-all duration-300 hover:text-gray-300"
                                    onClick={() => {
                                        setMobileMenuOpen(false);
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    Home
                                </Link>
                            </li>
                            {navItems.map((item) => (
                                <li key={item} className="border-b border-gray-800 pb-4">
                                    <NavItem
                                        item={item === 'Research' ? 'Research' : item}
                                        scrolled={true}
                                    />
                                </li>
                            ))}
                        </ul>
                        <div className="mt-auto pb-6">
                            <a
                                href="https://calendly.com/exaresearch"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-full bg-white text-black px-6 py-3 rounded-full text-base font-bold hover:bg-gray-200 transition-all duration-300 block text-center transform hover:scale-105 shadow-lg"
                            >
                                Book a Call
                            </a>
                        </div>
                    </nav>
                </div>
            </div>

            {/* Mobile Bottom Navigation Bar - new component for mobile */}
            <div className="md:hidden fixed bottom-0 left-0 right-0 bg-black/90 backdrop-blur-sm z-40 p-2 shadow-[0_-2px_10px_rgba(0,0,0,0.1)]">
                <div className="flex justify-around items-center">
                    <Link
                        to="/"
                        onClick={() => window.scrollTo(0, 0)}
                        className="p-2 flex flex-col items-center text-gray-400 transition-colors duration-300"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg>
                        <span className="text-xs mt-1">Home</span>
                    </Link>
                    <a
                        href="https://medium.com/exa-group"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-2 flex flex-col items-center text-gray-400 transition-colors duration-300"
                    >
                        <FileText className="h-6 w-6" />
                        <span className="text-xs mt-1">Research</span>
                    </a>
                    <Link
                        to="/treasury"
                        className="p-2 flex flex-col items-center text-gray-400 transition-colors duration-300"
                    >
                        <BarChart className="h-6 w-6" />
                        <span className="text-xs mt-1">Treasury</span>
                    </Link>
                    <Link
                        to="/about-exa"
                        className="p-2 flex flex-col items-center text-white transition-colors duration-300"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span className="text-xs mt-1">About</span>
                    </Link>
                </div>
            </div>

            {/* Scroll to top button - visible when scrolled down */}
            <button
                onClick={scrollToTop}
                className={`fixed z-40 bottom-20 right-4 p-3 rounded-full bg-black/80 backdrop-blur-sm text-white shadow-lg transition-all duration-300 ${showScrollTop ? 'opacity-80 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'} hover:opacity-100 active:scale-95 md:right-6 md:bottom-6`}
                aria-label="Scroll to top"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                </svg>
            </button>

            {/* Hero Section */}
            <section className={`relative bg-black text-white ${landingPadding} rounded-b-[0rem] shadow-2xl transition-all duration-500`}>
                <div className="container mx-auto px-4">
                    <div className="mb-10 text-center">
                        <h2 className="font-heading text-3xl md:text-4xl lg:text-5xl font-bold mb-5">A Collective of Builders</h2>
                        <p className="text-base md:text-lg lg:text-xl text-gray-300 max-w-3xl mx-auto">
                            A team of contributors from leading protocols and DAOs committed to democratizing
                            capital markets through the development of FOSS, sustainable token economic
                            models and best practices for DAOs' treasury longevity
                        </p>
                    </div>
                </div>
            </section>

            {/* Team Section */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4">
                    <div className="mb-16">
                        {/* Section Heading - improved spacing for mobile */}
                        <h3 className="font-heading text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center px-4">Core Contributors</h3>
                        <p className="text-base md:text-lg text-gray-800 mb-8 md:mb-10 text-center px-4">
                            Over 50 years of combined expertise in Web3
                        </p>
                        {/* Team Members Grid - improved spacing and padding for mobile */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 px-4 md:px-0">
                            {coreContributors.map((contributor, index) => (
                                <div key={index} className="text-center p-4 md:p-0">
                                    {/* Team Member Photo - adjusted for better mobile display */}
                                    <div
                                        onClick={() => setSelectedContributor(contributor)}
                                        className="w-28 h-28 sm:w-32 sm:h-32 md:w-36 md:h-36 rounded-full mx-auto mb-3 md:mb-4 overflow-hidden bg-gray-200 shadow-lg relative group cursor-pointer hover:shadow-xl transition-shadow"
                                    >
                                        <div className="absolute inset-0 bg-gradient-to-tr from-[#1a1a1a]/60 via-[#B8A164]/30 to-[#B8A164]/50 mix-blend-soft-light opacity-0 group-hover:opacity-100 transition-all duration-700 z-[11]"></div>
                                        <div className="absolute inset-0 bg-gradient-to-bl from-[#B8A164]/40 via-[#B8A164]/20 to-[#2a2a2a]/50 mix-blend-overlay opacity-0 group-hover:opacity-100 transition-all duration-700 delay-75 z-[12]"></div>
                                        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(184,161,100,0.2)_0%,rgba(26,26,26,0.7)_100%)] opacity-80 group-hover:opacity-0 transition-all duration-500 z-[13]"></div>
                                        <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-transparent to-black/30 mix-blend-color-burn opacity-60 group-hover:opacity-0 transition-all duration-500 z-[14]"></div>
                                        <div className="absolute inset-0 bg-[#B8A164] mix-blend-color opacity-20 group-hover:opacity-10 transition-all duration-700 z-[15]"></div>
                                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-all duration-500 z-[16] pointer-events-none">
                                            <span className="text-white text-sm bg-black/50 px-3 py-1 rounded-full backdrop-blur-sm">View Profile</span>
                                        </div>
                                        <img
                                            src={contributor.image}
                                            alt={contributor.name}
                                            className="w-full h-full object-cover scale-110 grayscale brightness-90 contrast-120 saturate-50 sepia-[0.4] group-hover:scale-100 group-hover:grayscale-0 group-hover:brightness-105 group-hover:contrast-100 group-hover:saturate-100 group-hover:sepia-[0.15] transition-all duration-700"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = '/placeholder-profile.jpg';
                                            }}
                                        />
                                    </div>
                                    {/* Team Member Info - improved text sizing and spacing for mobile */}
                                    <h4 className="font-heading text-lg md:text-xl font-bold mb-1">{contributor.name}</h4>
                                    <p className="text-sm md:text-base text-gray-800 mb-2 md:mb-3">{contributor.role}</p>
                                    <div className="flex flex-wrap justify-center gap-2 mb-3">
                                        {contributor.tags.map((tag, tagIndex) => (
                                            <span key={tagIndex} className="text-xs text-gray-500">{tag}{tagIndex < contributor.tags.length - 1 ? ' |' : ''}</span>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Contributor Modal */}
                        {selectedContributor && (
                            <div className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4">
                                <div className="bg-[#1a1a1a] rounded-2xl max-w-lg w-full p-6 relative shadow-2xl">
                                    <button
                                        onClick={() => setSelectedContributor(null)}
                                        className="absolute right-4 top-4 text-gray-400 hover:text-white transition-colors"
                                    >
                                        <X className="w-6 h-6" />
                                    </button>

                                    <div className="flex flex-col items-center">
                                        <div className="w-24 h-24 rounded-full overflow-hidden mb-4">
                                            <img
                                                src={selectedContributor.image}
                                                alt={selectedContributor.name}
                                                className="w-full h-full object-cover"
                                            />
                                        </div>

                                        <h3 className="text-2xl font-bold text-white mb-2">{selectedContributor.name}</h3>
                                        <div className="flex flex-wrap justify-center gap-x-2 mb-4">
                                            {selectedContributor.tags.map((tag, tagIndex) => (
                                                <span key={tagIndex} className="text-gray-400">{tag}{tagIndex < selectedContributor.tags.length - 1 ? ' |' : ''}</span>
                                            ))}
                                        </div>
                                        <p className="text-gray-300 text-center mb-6">{selectedContributor.description}</p>

                                        <div className="flex gap-4">
                                            {selectedContributor.twitter && (
                                                <a
                                                    href={selectedContributor.twitter}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-gray-400 hover:text-[#B8A164] transition-colors"
                                                >
                                                    <Twitter className="w-6 h-6" />
                                                </a>
                                            )}
                                            {selectedContributor.linkedin && (
                                                <a
                                                    href={selectedContributor.linkedin}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-gray-400 hover:text-[#B8A164] transition-colors"
                                                >
                                                    <Linkedin className="w-6 h-6" />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Partners Section */}
                    <div className="mb-16">
                        <h3 className="font-heading text-2xl md:text-3xl font-bold mb-10 text-center">Working For Pioneers</h3>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                            {trustedBy.map((partner, index) => (
                                <a
                                    key={index}
                                    href={partner.website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-white shadow-md rounded-xl overflow-hidden flex items-center justify-center p-3 transition-transform hover:scale-105"
                                >
                                    <img
                                        src={partner.logo}
                                        alt={partner.name}
                                        className="max-w-full max-h-14 object-contain"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/placeholder-logo.jpg';
                                        }}
                                    />
                                </a>
                            ))}
                        </div>
                    </div>

                    <div className="text-center">
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-black text-white py-16">
                <div className="container mx-auto px-4">
                    {/* Footer Top Section */}
                    <div className="flex flex-col items-center mb-12">
                        <Link to="/" className="font-heading text-2xl font-bold mb-6" onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav>
                            <ul className="flex flex-wrap justify-center gap-6 mt-4">
                                <li className="w-full sm:w-auto text-center mb-3">
                                    <a
                                        href="mailto:research@exagroup.xyz"
                                        className="text-base hover:text-gray-200 transition flex items-center justify-center"
                                        rel="noopener noreferrer"
                                    >
                                        <FileText className="mr-2" size={20} />
                                        Contact
                                    </a>
                                </li>
                                <li className="w-full sm:w-auto text-center mb-3">
                                    <a
                                        href="https://x.com/exagroupxyz"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-base hover:text-gray-200 transition flex items-center justify-center"
                                    >
                                        <Twitter className="mr-2" size={20} />
                                        Twitter
                                    </a>
                                </li>
                                <li className="w-full sm:w-auto text-center mb-3">
                                    <a
                                        href="https://medium.com/exa-group"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-base hover:text-gray-200 transition flex items-center justify-center"
                                    >
                                        <FileText className="mr-2" size={20} />
                                        Research
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {/* Address */}
                    <div className="text-center">
                        <p>43 Portsea Place, W2 2BW, London, United Kingdom</p>
                    </div>
                    <hr className="border-gray-700 my-8" />
                    {/* Copyright */}
                    <p className="text-center text-gray-400 text-sm">&copy; 2025 EXA RESEARCH LIMITED. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default AboutUs;