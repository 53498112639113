import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronRight, Building2, Network, Coins, FileText, Linkedin, Twitter, Shield, BarChart, Cog, Lock, LineChart, Settings, Target } from 'lucide-react';
import { Link } from 'react-router-dom';

/**
 * Navigation item component for header links
 * Handles both internal and external links with proper attributes
 */
const NavItem = ({ item, scrolled }) => {
    const href = item === 'Research'
        ? 'https://medium.com/exa-group'
        : (item === 'Home'
            ? '/'
            : `/${encodeURIComponent(item.toLowerCase().replace(/\s+/g, '-'))}`);

    // For external links, use <a> with security attributes
    if (href.startsWith('http')) {
        return (
            <a
                href={href}
                rel="noopener noreferrer"
                target="_blank"
                className={`transition-all duration-300 font-medium ${scrolled ? 'text-white hover:text-gray-200' : 'text-white hover:text-gray-200'}`}
            >
                {item}
            </a>
        );
    }

    // For internal links, use React Router's Link
    return (
        <Link
            to={href}
            className={`transition-all duration-300 font-medium ${scrolled ? 'text-white hover:text-gray-200' : 'text-white hover:text-gray-200'}`}
            onClick={() => window.scrollTo(0, 0)}
        >
            {item}
        </Link>
    );
};

/**
 * Expertise card component for displaying area of expertise
 * Used in the expertise section
 */
const ExpertiseCard = ({ icon: Icon, title, description }) => {
    return (
        <div className="bg-white p-5 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1 flex flex-col items-center active:scale-98 md:active:scale-100 relative overflow-hidden">
            <Icon className="w-10 h-10 text-black mb-3" />
            <h3 className="font-heading text-lg font-bold mb-2 text-center">{title}</h3>
            <p className="text-gray-800 text-sm text-center">{description}</p>

            {/* Mobile touch feedback effect */}
            <div className="absolute inset-0 bg-black/5 opacity-0 transition-opacity duration-200 md:hidden active:opacity-100"></div>
        </div>
    );
};

/**
 * Strategy card component for displaying treasury strategies
 * Used in the treasury management framework section
 */
const StrategyCard = ({ icon: Icon, title, points }) => {
    return (
        <div className="bg-black text-white p-6 rounded-2xl shadow-xl transition-all duration-300 hover:bg-gray-900 relative overflow-hidden active:scale-[0.98] md:active:scale-100">
            <div className="p-3 bg-white/10 w-fit rounded-xl mb-4">
                <Icon className="w-6 h-6 text-white" />
            </div>
            <h3 className="font-heading text-xl font-bold mb-4">{title}</h3>
            <ul className="space-y-2">
                {points.map((point, index) => (
                    <li key={index} className="flex items-start">
                        <div className="text-green-400 mr-2 mt-1">•</div>
                        <span className="text-gray-300 text-sm">{point}</span>
                    </li>
                ))}
            </ul>

            {/* Mobile touch ripple effect */}
            <div className="absolute -inset-px rounded-2xl overflow-hidden md:hidden">
                <div className="absolute inset-0 bg-white/10 scale-0 opacity-0 origin-center active:scale-[4] active:opacity-100 transition-all duration-300 rounded-full pointer-events-none"></div>
            </div>
        </div>
    );
};

/**
 * Detail section component for displaying details about the treasury service
 * Used in the service details section
 */
const DetailSection = ({ icon: Icon, title, description }) => {
    return (
        <div className="flex flex-col md:flex-row items-start gap-4 p-5 rounded-xl hover:bg-gray-50 transition-colors duration-300 active:bg-gray-100 md:active:bg-transparent">
            <div className="bg-gray-100 p-3 rounded-lg">
                <Icon className="w-6 h-6 text-black" />
            </div>
            <div>
                <h3 className="font-heading text-lg font-bold mb-2">{title}</h3>
                <p className="text-gray-700 text-sm">{description}</p>
            </div>
        </div>
    );
};

/**
 * Treasury page component
 * Displays information about treasury management services
 */
const Treasury = () => {
    // State for mobile menu visibility and page scroll position
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [landingPadding, setLandingPadding] = useState('pt-32 pb-24 md:pt-44 md:pb-32');
    const [activeSection, setActiveSection] = useState('treasury');
    const [showScrollTop, setShowScrollTop] = useState(false);

    const navItems = ['Treasury', 'Research', 'About Exa'];

    // Handle scroll events to adjust header and landing section appearance
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 50);
            setShowScrollTop(scrollPosition > 600);

            if (scrollPosition > 500) {
                setLandingPadding('pt-20 pb-16 md:pt-28 md:pb-20');
            } else if (scrollPosition > 200) {
                setLandingPadding('pt-24 pb-20 md:pt-36 md:pb-24');
            } else {
                setLandingPadding('pt-32 pb-24 md:pt-44 md:pb-32');
            }

            // Determine active section for mobile navigation
            const sections = document.querySelectorAll('section[id]');
            sections.forEach(section => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                if (scrollPosition >= sectionTop - 200 && scrollPosition < sectionTop + sectionHeight - 200) {
                    setActiveSection(section.getAttribute('id'));
                }
            });
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Function to scroll to top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Treasury strategies data
    const strategies = [
        {
            icon: Shield,
            title: "Treasury Protection & Growth Strategy",
            points: [
                "Core focus on capital preservation through battle-tested yield strategies",
                "Strategic deployment across multiple DeFi ecosystems for optimal diversification",
                "Deep relationships with leading DeFi protocols enabling access to exclusive opportunities",
                "Access to exclusive on-chain private deals for enhanced yields",
                "Implementation of sophisticated hedging strategies",
                "Regular portfolio rebalancing to maintain optimal risk-adjusted returns",
                "Continuous monitoring of protocol health and market conditions"
            ]
        },
        {
            icon: BarChart,
            title: "Return Projections & Flexibility",
            points: [
                "Detailed scenarios reflecting current market conditions, net of all fees",
                "Conservative baseline projections with upside potential",
                "Customizable risk tolerance and investment objectives",
                "Dynamic portfolio adjustment based on market conditions",
                "Regular performance reviews and strategy optimization",
                "Market dynamics impact analysis and adaptation",
                "Transparent reporting on all activities and results"
            ]
        },
        {
            icon: Cog,
            title: "Operational Framework",
            points: [
                "Exclusive focus on on-chain treasury management and yield generation",
                "Seamless integration with existing market makers for OTC conversions",
                "Clear division of responsibilities ensuring operational efficiency",
                "Foundation setup and ongoing legal management support",
                "Transparent reporting and communication channels",
                "Dedicated support team for immediate response",
                "Regular stakeholder updates and governance participation"
            ]
        },
        {
            icon: Lock,
            title: "Technical Infrastructure",
            points: [
                "Proprietary tech stack with automated guardians and real-time monitoring",
                "Multi-layered risk management system with instant response capabilities",
                "Non-custodial setup with granular permissions through Safe smart contracts",
                "Advanced security protocols and regular audits",
                "Automated monitoring and alert systems",
                "Redundant backup systems and disaster recovery protocols",
                "Regular security assessments and penetration testing"
            ]
        }
    ];

    // Additional treasury details data
    const additionalDetails = [
        {
            icon: LineChart,
            title: "Performance Tracking",
            description: "Comprehensive performance monitoring with detailed analytics and reporting on all treasury activities, including yield generation, risk metrics, and market exposure."
        },
        {
            icon: Settings,
            title: "Protocol Integration",
            description: "Seamless integration with major DeFi protocols and platforms, enabling efficient capital deployment and risk management across multiple ecosystems."
        },
        {
            icon: Target,
            title: "Risk Management",
            description: "Multi-layered risk management approach combining automated systems, human oversight, and strategic diversification to protect treasury assets."
        }
    ];

    return (
        <div className="font-sans bg-white text-black">
            {/* Fixed navigation header - consistent with other components */}
            <header className={`fixed w-full top-0 z-50 transition-all duration-500 backdrop-blur-sm ${scrolled ? 'bg-black/90 shadow-lg py-2' : 'bg-transparent py-3'}`}>
                <div className="container mx-auto px-4">
                    <div className="flex justify-between items-center">
                        {/* Logo/Brand */}
                        <Link
                            to="/"
                            className={`font-heading text-xl md:text-2xl font-bold transition-all duration-300 ${scrolled ? 'text-white' : 'text-white'} hover:scale-105`}
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            ExaGroup
                        </Link>

                        {/* Desktop Navigation */}
                        <div className="flex items-center space-x-6">
                            <nav className="hidden md:block">
                                <ul className="flex space-x-6">
                                    {navItems.map((item) => (
                                        <li key={item}><NavItem item={item === 'Research' ? 'Research' : item} scrolled={scrolled} /></li>
                                    ))}
                                </ul>
                            </nav>

                            {/* Call to Action Button - Desktop */}
                            <a
                                href="https://calendly.com/exaresearch"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`hidden md:block px-6 py-2 rounded-full text-sm font-bold transition-all duration-300 transform hover:scale-105 ${scrolled ? 'bg-white text-black hover:bg-gray-200' : 'bg-white text-black hover:bg-gray-200'}`}
                            >
                                Book a Call
                            </a>

                            {/* Mobile Menu Toggle */}
                            <button
                                className="md:hidden transform hover:scale-105 transition-transform duration-300"
                                onClick={() => setMobileMenuOpen(true)}
                                aria-label="Open menu"
                            >
                                <Menu className="w-7 h-7 text-white" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {/* Mobile menu overlay - Improved with smoother animations */}
            <div
                className={`fixed inset-0 bg-black/90 z-50 backdrop-blur-sm transition-all duration-500 ${mobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                onClick={() => setMobileMenuOpen(false)}
            >
                <div
                    className={`fixed right-0 top-0 bottom-0 w-4/5 max-w-sm bg-black shadow-2xl transform transition-all duration-500 ease-in-out ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* Mobile menu content - Enhanced with better spacing and animations */}
                    <nav className="p-8 pt-16 h-full flex flex-col">
                        <div className="flex justify-end mb-10">
                            <button
                                onClick={() => setMobileMenuOpen(false)}
                                className="p-2 rounded-full hover:bg-gray-800 transition-colors duration-300"
                                aria-label="Close menu"
                            >
                                <X className="w-7 h-7 text-white" />
                            </button>
                        </div>
                        <ul className="space-y-6 flex-1">
                            <li>
                                <Link
                                    to="/"
                                    className="block text-xl font-heading font-bold text-white mb-2 transition-all duration-300 hover:text-gray-300"
                                    onClick={() => {
                                        setMobileMenuOpen(false);
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    Home
                                </Link>
                            </li>
                            {navItems.map((item) => (
                                <li key={item} className="border-b border-gray-800 pb-4">
                                    <NavItem
                                        item={item === 'Research' ? 'Research' : item}
                                        scrolled={true}
                                    />
                                </li>
                            ))}
                        </ul>
                        <div className="mt-auto pb-6">
                            <a
                                href="https://calendly.com/exaresearch"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-full bg-white text-black px-6 py-3 rounded-full text-base font-bold hover:bg-gray-200 transition-all duration-300 block text-center transform hover:scale-105 shadow-lg"
                            >
                                Book a Call
                            </a>
                        </div>
                    </nav>
                </div>
            </div>

            {/* Mobile Bottom Navigation Bar - new component for mobile */}
            <div className="md:hidden fixed bottom-0 left-0 right-0 bg-black/90 backdrop-blur-sm z-40 p-2 shadow-[0_-2px_10px_rgba(0,0,0,0.1)]">
                <div className="flex justify-around items-center">
                    <Link
                        to="/"
                        onClick={() => window.scrollTo(0, 0)}
                        className="p-2 flex flex-col items-center text-gray-400 transition-colors duration-300"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg>
                        <span className="text-xs mt-1">Home</span>
                    </Link>
                    <a
                        href="https://medium.com/exa-group"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-2 flex flex-col items-center text-gray-400 transition-colors duration-300"
                    >
                        <FileText className="h-6 w-6" />
                        <span className="text-xs mt-1">Research</span>
                    </a>
                    <Link
                        to="/treasury"
                        className="p-2 flex flex-col items-center text-white transition-colors duration-300"
                    >
                        <BarChart className="h-6 w-6" />
                        <span className="text-xs mt-1">Treasury</span>
                    </Link>
                    <Link
                        to="/about-exa"
                        className="p-2 flex flex-col items-center text-gray-400 transition-colors duration-300"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span className="text-xs mt-1">About</span>
                    </Link>
                </div>
            </div>

            {/* Scroll to top button - visible when scrolled down */}
            <button
                onClick={scrollToTop}
                className={`fixed z-40 bottom-20 right-4 p-3 rounded-full bg-black/80 backdrop-blur-sm text-white shadow-lg transition-all duration-300 ${showScrollTop ? 'opacity-80 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'} hover:opacity-100 active:scale-95 md:right-6 md:bottom-6`}
                aria-label="Scroll to top"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                </svg>
            </button>

            {/* Hero Section */}
            <section className={`relative bg-black text-white ${landingPadding} rounded-b-[0rem] shadow-2xl transition-all duration-500`}>
                <div className="container mx-auto px-4">
                    <h1 className="font-heading text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-5">
                        On-Chain Treasury Management for DAOs
                    </h1>
                    <p className="text-center text-base md:text-lg text-gray-300 mb-8 max-w-3xl mx-auto">
                        Deep DeFi expertise with institutional-grade risk management to protect and grow your digital assets and ensure protocol longevity.
                    </p>
                </div>
            </section>

            {/* Expertise Section */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                        <ExpertiseCard
                            icon={Building2}
                            title="Unmatched Expertise in Decentralized Finance"
                            description="With over four years of hands-on experience in DeFi, we've extensively researched, interacted with, and deployed capital across various protocols. This practical knowledge allows us to design and implement effective, on-chain financial systems tailored to the unique challenges of decentralized organizations."
                        />
                        <ExpertiseCard
                            icon={Network}
                            title="Unparalleled Network"
                            description="Carefully cultivated through professionalism, reactivity and strong collaborating with market leaders."
                        />
                        <ExpertiseCard
                            icon={Coins}
                            title="Specialists in Stablecoin & ETH Strategy"
                            description="Stablecoins and DeFi lie at the heart of our expertise. By integrating off-chain risk assessments with fully on-chain execution, we provide seamless solutions in collaboration with trusted partners like Safe and Gauntlet. Our focus is always on advocating for our clients' interests and ensuring their long-term success in an ever-evolving financial landscape."
                        />
                    </div>
                </div>
            </section>

            {/* Treasury Management Framework */}
            <section className="py-16 bg-gray-100">
                <div className="container mx-auto px-4">
                    <h2 className="font-heading text-2xl md:text-3xl font-bold mb-8 text-center">
                        Comprehensive Treasury Management Framework
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {strategies.map((strategy, index) => (
                            <StrategyCard
                                key={index}
                                icon={strategy.icon}
                                title={strategy.title}
                                points={strategy.points}
                            />
                        ))}
                    </div>
                </div>
            </section>

            {/* Advanced Treasury Solutions */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="font-heading text-2xl md:text-3xl font-bold mb-6 text-center">
                        Advanced Treasury Solutions
                    </h2>
                    <p className="text-base text-gray-800 max-w-3xl mx-auto text-center mb-12">
                        Our approach combines deep DeFi expertise with institutional-grade risk management to protect and grow your treasury assets while maintaining full transparency and control.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {additionalDetails.map((detail, index) => (
                            <DetailSection
                                key={index}
                                icon={detail.icon}
                                title={detail.title}
                                description={detail.description}
                            />
                        ))}
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-black text-white py-16">
                <div className="container mx-auto px-4">
                    {/* Footer Top Section */}
                    <div className="flex flex-col items-center mb-12">
                        <Link to="/" className="font-heading text-2xl font-bold mb-6" onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav>
                            <ul className="flex flex-wrap justify-center gap-6 mt-4">
                                <li className="w-full sm:w-auto text-center mb-3">
                                    <a
                                        href="mailto:research@exagroup.xyz"
                                        className="text-base hover:text-gray-200 transition flex items-center justify-center"
                                        rel="noopener noreferrer"
                                    >
                                        <FileText className="mr-2" size={20} />
                                        Contact
                                    </a>
                                </li>
                                <li className="w-full sm:w-auto text-center mb-3">
                                    <a
                                        href="https://x.com/exagroupxyz"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-base hover:text-gray-200 transition flex items-center justify-center"
                                    >
                                        <Twitter className="mr-2" size={20} />
                                        Twitter
                                    </a>
                                </li>
                                <li className="w-full sm:w-auto text-center mb-3">
                                    <a
                                        href="https://medium.com/exa-group"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-base hover:text-gray-200 transition flex items-center justify-center"
                                    >
                                        <FileText className="mr-2" size={20} />
                                        Research
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {/* Address */}
                    <div className="text-center">
                        <p>43 Portsea Place, W2 2BW, London, United Kingdom</p>
                    </div>
                    <hr className="border-gray-700 my-8" />
                    {/* Copyright */}
                    <p className="text-center text-gray-400 text-sm">&copy; 2025 EXA RESEARCH LIMITED. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Treasury;
