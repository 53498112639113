import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Treasury from './components/Treasury';

// Security enhancement: Add a custom error boundary to catch and handle errors gracefully
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center p-4">
          <h1 className="font-heading text-2xl font-bold mb-4">Something went wrong</h1>
          <p className="mb-4">We apologize for the inconvenience. Please try refreshing the page.</p>
          <button
            onClick={() => window.location.href = '/'}
            className="px-4 py-2 bg-white text-black rounded-md hover:bg-gray-200 transition"
          >
            Return to Home
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

// Security enhancement: Add a secure router with proper route handling
function App() {
  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          {/* Define valid routes */}
          <Route path="/" element={<Home />} />
          <Route path="/treasury" element={<Treasury />} />
          <Route path="/about-exa" element={<AboutUs />} />

          {/* Security enhancement: Redirect invalid routes to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
