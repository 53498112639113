import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Security best practices
// 1. Use React.StrictMode to catch potential problems
// 2. Ensure all components are properly sanitized
// 3. Implement Content Security Policy (done in index.html)

// Create a secure root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app with StrictMode for additional security checks
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Security measures for the window object
if (window.opener) {
  // Prevent reverse tabnabbing
  window.opener = null;
}

// Disable console in production to prevent information leakage
if (process.env.NODE_ENV === 'production') {
  const noop = () => { };

  // Save original console for emergencies
  const originalConsole = { ...console };

  // Only disable non-critical methods
  console.log = noop;
  console.info = noop;
  console.debug = noop;

  // Keep error and warn for debugging critical issues
  // console.error = noop;
  // console.warn = noop;

  // Create a secure emergency logging method
  window.__secureLog = (message) => {
    originalConsole.log(message);
  };
}