import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronRight, FileText, Key, PieChart, Beaker, Linkedin, Twitter, Shield, BarChart, Settings } from 'lucide-react';
import { Link } from 'react-router-dom';

/**
 * Navigation item component for header links
 * Handles both internal and external links with proper attributes
 */
const NavItem = ({ item, scrolled }) => {
    const href = item === 'Research'
        ? 'https://medium.com/exa-group'
        : (item === 'Home'
            ? '/'
            : `/${encodeURIComponent(item.toLowerCase().replace(/\s+/g, '-'))}`);

    // For external links, use <a> with security attributes
    if (href.startsWith('http')) {
        return (
            <a
                href={href}
                rel="noopener noreferrer"
                target="_blank"
                className={`transition-all duration-300 font-medium relative group ${scrolled ? 'text-white hover:text-gray-200' : 'text-white hover:text-gray-200'}`}
            >
                {item}
            </a>
        );
    }

    // For internal links, use React Router's Link
    return (
        <Link
            to={href}
            className={`transition-all duration-300 font-medium relative group ${scrolled ? 'text-white hover:text-gray-200' : 'text-white hover:text-gray-200'}`}
            onClick={() => window.scrollTo(0, 0)}
        >
            {item}
        </Link>
    );
};

/**
 * Service item component for displaying service features
 * Used in the Community Programs section
 */
const ServiceItem = ({ icon: Icon, title, description }) => (
    <div className="group bg-white p-6 rounded-2xl shadow-xl transition-all duration-500 hover:shadow-2xl hover:-translate-y-2 hover:bg-gray-50 flex flex-col items-center text-center h-full relative overflow-hidden cursor-pointer transform perspective-1000 active:scale-95 md:active:scale-100">
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-0 group-hover:opacity-10 transform translate-x-full group-hover:translate-x-0 transition-all duration-1000" />
        <Icon className="w-10 h-10 mb-4 text-black transition-transform duration-500 group-hover:scale-110" />
        <h3 className="font-heading text-xl font-semibold mb-3 transition-colors duration-300 group-hover:text-gray-900">{title}</h3>
        <p className="text-gray-800 text-sm transition-colors duration-300 group-hover:text-gray-700">{description}</p>
        <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-gray-200 via-gray-400 to-gray-200 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />
    </div>
);

/**
 * Methodology item component for displaying token engineering approaches
 * Used in the Token Engineering section
 */
const MethodologyItem = ({ icon: Icon, title, description }) => (
    <div className="group bg-white p-5 rounded-xl border border-gray-100 shadow-md transition-all duration-300 hover:shadow-xl hover:border-gray-200 flex flex-col h-full relative overflow-hidden transform active:scale-95 md:active:scale-100">
        <div className="flex items-start mb-3">
            <div className="p-2 bg-gray-100 rounded-lg mr-3 group-hover:bg-gray-200 transition-colors duration-300">
                <Icon className="w-6 h-6 text-black" />
            </div>
            <h3 className="font-heading text-lg font-semibold transition-colors duration-300 group-hover:text-gray-900 mt-1">{title}</h3>
        </div>
        <p className="text-gray-700 text-sm leading-relaxed transition-colors duration-300 group-hover:text-gray-800">{description}</p>

        {/* Mobile touch feedback element */}
        <div className="absolute inset-0 bg-gray-900/5 opacity-0 transition-opacity duration-300 md:hidden active:opacity-100"></div>
    </div>
);

/**
 * Treasury item component for displaying treasury management services
 * Used in the Services section 
 */
const TreasuryItem = ({ icon: Icon, title, description }) => (
    <div className="group bg-gray-900 p-6 rounded-2xl shadow-lg transition-all duration-500 hover:shadow-2xl hover:bg-gray-800 flex flex-col h-full relative overflow-hidden cursor-pointer transform active:scale-95 md:active:scale-100">
        <div className="p-3 bg-white/10 w-fit rounded-xl mb-4 transition-all duration-300 group-hover:bg-white/15">
            <Icon className="w-6 h-6 text-white" />
        </div>
        <h3 className="font-heading text-xl font-semibold mb-3 text-white">{title}</h3>
        <p className="text-gray-300 text-sm transition-colors duration-300 group-hover:text-gray-200">{description}</p>

        {/* Mobile-specific ripple effect on touch */}
        <div className="absolute -inset-px rounded-2xl overflow-hidden md:hidden">
            <div className="absolute inset-0 bg-white/10 scale-0 opacity-0 origin-center active:scale-[4] active:opacity-100 transition-all duration-300 rounded-full pointer-events-none"></div>
        </div>
    </div>
);

/**
 * Home page component
 * Main landing page for the ExaGroup website
 */
const Home = () => {
    // State for mobile menu visibility and page scroll position
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [landingPadding, setLandingPadding] = useState('pt-32 pb-24 md:pt-44 md:pb-32');
    const [activeSection, setActiveSection] = useState('home');
    const [showScrollTop, setShowScrollTop] = useState(false);

    const navItems = ['Treasury', 'Research', 'About Exa'];

    // Handle scroll events to adjust header and landing section appearance
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 50);
            setShowScrollTop(scrollPosition > 600);

            if (scrollPosition > 500) {
                setLandingPadding('pt-20 pb-16 md:pt-28 md:pb-20');
            } else if (scrollPosition > 200) {
                setLandingPadding('pt-24 pb-20 md:pt-36 md:pb-24');
            } else {
                setLandingPadding('pt-32 pb-24 md:pt-44 md:pb-32');
            }

            // Determine active section for mobile navigation
            const sections = document.querySelectorAll('section[id]');
            sections.forEach(section => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                if (scrollPosition >= sectionTop - 200 && scrollPosition < sectionTop + sectionHeight - 200) {
                    setActiveSection(section.getAttribute('id'));
                }
            });
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Function to scroll to top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className="font-sans bg-white text-black">
            {/* Fixed navigation header */}
            <header className={`fixed w-full top-0 z-50 transition-all duration-500 backdrop-blur-sm ${scrolled ? 'bg-black/90 shadow-lg py-2' : 'bg-transparent py-3'}`}>
                <div className="container mx-auto px-4">
                    <div className="flex justify-between items-center">
                        {/* Logo/Brand */}
                        <Link
                            to="/"
                            className={`font-heading text-xl md:text-2xl font-bold transition-all duration-300 ${scrolled ? 'text-white' : 'text-white'} hover:scale-105`}
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            ExaGroup
                        </Link>

                        {/* Desktop Navigation */}
                        <div className="flex items-center space-x-6">
                            <nav className="hidden md:block">
                                <ul className="flex space-x-6">
                                    {navItems.map((item) => (
                                        <li key={item}><NavItem item={item === 'Research' ? 'Research' : item} scrolled={scrolled} /></li>
                                    ))}
                                </ul>
                            </nav>

                            {/* Call to Action Button - Desktop */}
                            <a
                                href="https://calendly.com/exaresearch"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`hidden md:block px-6 py-2 rounded-full text-sm font-bold transition-all duration-300 transform hover:scale-105 ${scrolled ? 'bg-white text-black hover:bg-gray-200' : 'bg-white text-black hover:bg-gray-200'}`}
                            >
                                Book a Call
                            </a>

                            {/* Mobile Menu Toggle */}
                            <button
                                className="md:hidden transform hover:scale-105 transition-transform duration-300"
                                onClick={() => setMobileMenuOpen(true)}
                                aria-label="Open menu"
                            >
                                <Menu className="w-7 h-7 text-white" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {/* Mobile menu overlay - Improved with smoother animations */}
            <div
                className={`fixed inset-0 bg-black/90 z-50 backdrop-blur-sm transition-all duration-500 ${mobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                onClick={() => setMobileMenuOpen(false)}
            >
                <div
                    className={`fixed right-0 top-0 bottom-0 w-4/5 max-w-sm bg-black shadow-2xl transform transition-all duration-500 ease-in-out ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* Mobile menu content - Enhanced with better spacing and animations */}
                    <nav className="p-8 pt-16 h-full flex flex-col">
                        <div className="flex justify-end mb-10">
                            <button
                                onClick={() => setMobileMenuOpen(false)}
                                className="p-2 rounded-full hover:bg-gray-800 transition-colors duration-300"
                                aria-label="Close menu"
                            >
                                <X className="w-7 h-7 text-white" />
                            </button>
                        </div>
                        <ul className="space-y-6 flex-1">
                            <li>
                                <Link
                                    to="/"
                                    className="block text-xl font-heading font-bold text-white mb-2 transition-all duration-300 hover:text-gray-300"
                                    onClick={() => {
                                        setMobileMenuOpen(false);
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    Home
                                </Link>
                            </li>
                            {navItems.map((item) => (
                                <li key={item} className="border-b border-gray-800 pb-4">
                                    <NavItem
                                        item={item === 'Research' ? 'Research' : item}
                                        scrolled={true}
                                    />
                                </li>
                            ))}
                        </ul>
                        <div className="mt-auto pb-6">
                            <a
                                href="https://calendly.com/exaresearch"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-full bg-white text-black px-6 py-3 rounded-full text-base font-bold hover:bg-gray-200 transition-all duration-300 block text-center transform hover:scale-105 shadow-lg"
                            >
                                Book a Call
                            </a>
                        </div>
                    </nav>
                </div>
            </div>

            {/* Mobile Bottom Navigation Bar - New component for mobile */}
            <div className="md:hidden fixed bottom-0 left-0 right-0 bg-black/90 backdrop-blur-sm z-40 p-2 shadow-[0_-2px_10px_rgba(0,0,0,0.1)]">
                <div className="flex justify-around items-center">
                    <Link
                        to="/"
                        onClick={() => window.scrollTo(0, 0)}
                        className={`p-2 flex flex-col items-center ${activeSection === 'home' ? 'text-white' : 'text-gray-400'} transition-colors duration-300`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg>
                        <span className="text-xs mt-1">Home</span>
                    </Link>
                    <a
                        href="https://medium.com/exa-group"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-2 flex flex-col items-center text-gray-400 transition-colors duration-300"
                    >
                        <FileText className="h-6 w-6" />
                        <span className="text-xs mt-1">Research</span>
                    </a>
                    <Link
                        to="/treasury"
                        className={`p-2 flex flex-col items-center ${activeSection === 'treasury' ? 'text-white' : 'text-gray-400'} transition-colors duration-300`}
                    >
                        <BarChart className="h-6 w-6" />
                        <span className="text-xs mt-1">Treasury</span>
                    </Link>
                    <Link
                        to="/about-exa"
                        className={`p-2 flex flex-col items-center ${activeSection === 'about' ? 'text-white' : 'text-gray-400'} transition-colors duration-300`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span className="text-xs mt-1">About</span>
                    </Link>
                </div>
            </div>

            {/* Scroll to top button - visible when scrolled down */}
            <button
                onClick={scrollToTop}
                className={`fixed z-40 bottom-20 right-4 p-3 rounded-full bg-black/80 backdrop-blur-sm text-white shadow-lg transition-all duration-300 ${showScrollTop ? 'opacity-80 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'} hover:opacity-100 active:scale-95 md:right-6 md:bottom-6`}
                aria-label="Scroll to top"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                </svg>
            </button>

            {/* Hero Section */}
            <section id="home" className={`relative bg-gradient-to-br from-black via-gray-900 to-black text-white ${landingPadding} rounded-b-[0rem] shadow-2xl transition-all duration-500 overflow-hidden`}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/50 to-black/20" />
                <div className="container mx-auto px-4 relative">
                    <div className="flex flex-col md:flex-row items-center md:items-center">
                        {/* Hero Text Content */}
                        <div className="md:w-1/2 mb-8 md:mb-0 w-full flex flex-col justify-center">
                            <h1 className="font-heading text-3xl md:text-4xl lg:text-5xl font-bold mb-6 leading-tight text-center md:text-left transform transition-transform duration-700 hover:scale-[1.02]">
                                Building The Tokenized Ventures of Tomorrow
                            </h1>
                            <p className="text-lg md:text-xl mb-8 text-gray-300 text-center md:text-left leading-relaxed">
                                We help businesses build and launch sustainable tokenized ventures by utilizing data-driven methodologies and proven strategies from the industry.
                            </p>
                            {/* Mobile-only CTA button */}
                            <div className="flex justify-center md:hidden mb-6">
                                <a
                                    href="https://calendly.com/exaresearch"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="px-8 py-3 bg-white text-black rounded-full text-base font-bold transform transition-all duration-300 hover:scale-105 shadow-lg flex items-center justify-center"
                                >
                                    <span>Book a Call</span>
                                    <ChevronRight className="ml-1 w-5 h-5" />
                                </a>
                            </div>
                        </div>
                        {/* Hero Image */}
                        <div className="md:w-1/2 mt-4 md:mt-0">
                            <div className="aspect-w-4 aspect-h-3 mx-auto max-w-lg transform transition-all duration-700 hover:scale-105">
                                <img
                                    src="/Worked for Logos copy/logo enhanced.png"
                                    alt="Network visualization"
                                    className="w-full h-full object-cover rounded-2xl shadow-2xl transition-shadow duration-300 hover:shadow-[0_20px_50px_rgba(0,0,0,0.3)]"
                                    loading="eager"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Scroll indicator for mobile */}
                <div className="absolute bottom-4 left-0 right-0 flex justify-center md:hidden animate-bounce">
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-white/10 backdrop-blur-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                        </svg>
                    </div>
                </div>
            </section>

            {/* Token Engineering Section */}
            <section id="engineering" className="py-16 md:py-20 bg-gray-100 relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-50/50 to-transparent opacity-50" />
                <div className="container mx-auto px-4 relative">
                    {/* Section Heading */}
                    <h2 className="font-heading text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center transform transition-transform duration-700 hover:scale-[1.02]">
                        Token Engineering
                    </h2>
                    <p className="text-base md:text-lg text-center mb-8 md:mb-12 max-w-3xl mx-auto transition-all duration-500 hover:text-gray-800">
                        Empirical research and analysis to understand protocols' and communities' needs and lay the foundation for sustainable token economies and incentives systems.
                    </p>
                    {/* Methodology Cards */}
                    <div className="flex justify-center">
                        <div className="w-full max-w-6xl bg-white p-4 md:p-8 rounded-2xl shadow-xl transition-all duration-500 hover:shadow-[0_15px_35px_rgba(0,0,0,0.1)]">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
                                <MethodologyItem
                                    icon={FileText}
                                    title="Analysis & Benchmarking"
                                    description="We examine your situation and conduct comparative analyses to deeply understand tokenomics in similar projects."
                                />
                                <MethodologyItem
                                    icon={PieChart}
                                    title="Supply & Allocation"
                                    description="We craft optimal token emission schedules and distribution strategies aligned with your project's goals and sustainability."
                                />
                                <MethodologyItem
                                    icon={Key}
                                    title="Mechanism Design"
                                    description="We design and implement robust token mechanics and governance structures tailored to your specific objectives."
                                />
                                <MethodologyItem
                                    icon={Beaker}
                                    title="Simulation & Testing"
                                    description="We thoroughly test tokenomics in simulated environments to ensure they function as expected in real-world scenarios."
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile-specific touch hint */}
                <div className="mt-6 text-center text-sm text-gray-500 md:hidden">
                    <span className="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11" />
                        </svg>
                        Tap cards to see details
                    </span>
                </div>
            </section>

            {/* Treasury Management Section */}
            <section className="py-20 bg-gray-100 relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-br from-transparent via-white to-transparent opacity-70" />
                <div className="container mx-auto px-4 relative">
                    {/* Section Heading */}
                    <h2 className="font-heading text-3xl font-bold mb-6 text-center transform transition-transform duration-700 hover:scale-[1.02]">
                        Treasury Management for DAOs
                    </h2>
                    <p className="text-lg text-center mb-12 max-w-3xl mx-auto transition-all duration-500 hover:text-gray-800">
                        Comprehensive treasury management and strategic investment advisory to protect and grow your digital assets through battle-tested strategies and cutting-edge technology.
                    </p>
                    {/* Treasury Feature Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <TreasuryItem
                            icon={Shield}
                            title="Asset Protection"
                            description="Multi-layered security framework with automated monitoring and risk management systems to safeguard your treasury assets."
                        />
                        <TreasuryItem
                            icon={BarChart}
                            title="Yield Generation"
                            description="Strategic deployment across DeFi ecosystems, accessing exclusive opportunities while maintaining strong risk management practices."
                        />
                        <TreasuryItem
                            icon={Settings}
                            title="Technical Infrastructure"
                            description="Proprietary tech stack featuring automated guardians, real-time monitoring, and granular controls through secure smart contracts."
                        />
                    </div>
                </div>
            </section>

            {/* Governance Section */}
            <section className="py-20 bg-white relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-50/50 to-transparent opacity-50" />
                <div className="container mx-auto px-4 relative">
                    {/* Section Heading */}
                    <h2 className="font-heading text-3xl font-bold mb-4 text-center transform transition-transform duration-700 hover:scale-[1.02]">
                        Governance Systems & Community Programs
                    </h2>
                    <p className="text-lg text-center mb-12 max-w-3xl mx-auto transition-all duration-500 hover:text-gray-800">
                        Empowering DAOs with fair governance systems and maximise community engagement through bespoke programs
                    </p>
                    {/* Service Feature Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        <ServiceItem
                            icon={PieChart}
                            title="Governance Frameworks"
                            description="Designing and implementing governance structures that balance decentralization with operational efficiency."
                        />
                        <ServiceItem
                            icon={Shield}
                            title="Voting Mechanisms"
                            description="Creating fair and secure voting systems that prevent plutocracy while encouraging active participation."
                        />
                        <ServiceItem
                            icon={BarChart}
                            title="Incentive Programs"
                            description="Developing sustainable reward systems that align community actions with project goals."
                        />
                        <ServiceItem
                            icon={Settings}
                            title="Community Building"
                            description="Fostering engaged communities through targeted programs and meaningful contribution opportunities."
                        />
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-black text-white py-16">
                <div className="container mx-auto px-4">
                    {/* Footer Top Section */}
                    <div className="flex flex-col items-center mb-12">
                        <Link to="/" className="font-heading text-2xl font-bold mb-6" onClick={() => window.scrollTo(0, 0)}>ExaGroup</Link>
                        <nav>
                            <ul className="flex flex-wrap justify-center gap-6 mt-4">
                                <li className="w-full sm:w-auto text-center mb-3">
                                    <a
                                        href="mailto:research@exagroup.xyz"
                                        className="text-base hover:text-gray-200 transition-all duration-300 flex items-center justify-center group"
                                        rel="noopener noreferrer"
                                    >
                                        <FileText className="mr-2 transition-transform duration-300 group-hover:scale-110" size={20} />
                                        Contact
                                    </a>
                                </li>
                                <li className="w-full sm:w-auto text-center mb-3">
                                    <a
                                        href="https://x.com/exagroupxyz"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-base hover:text-gray-200 transition-all duration-300 flex items-center justify-center group"
                                    >
                                        <Twitter className="mr-2 transition-transform duration-300 group-hover:scale-110" size={20} />
                                        Twitter
                                    </a>
                                </li>
                                <li className="w-full sm:w-auto text-center mb-3">
                                    <a
                                        href="https://medium.com/exa-group"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-base hover:text-gray-200 transition-all duration-300 flex items-center justify-center group"
                                    >
                                        <FileText className="mr-2 transition-transform duration-300 group-hover:scale-110" size={20} />
                                        Research
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {/* Address */}
                    <div className="text-center">
                        <p>43 Portsea Place, W2 2BW, London, United Kingdom</p>
                    </div>
                    <hr className="border-gray-700 my-8" />
                    {/* Copyright */}
                    <p className="text-center text-gray-400 text-sm">&copy; 2025 EXA RESEARCH LIMITED. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Home;